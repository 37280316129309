export const STATUS_LIST = {
  1: "created",
  2: "confirmed",
  3: "picked",
  4: "transit",
  0: "cancelled",
  5: "arrived",
};
// (1, ""),
// (2, ""),
// (3, ""),
// (4, ""),
// (5, ""),

export const toastProps = {
  duration: 2000,
  isClosable: true,
};
